import { CurrencyPipe, DatePipe, isPlatformBrowser, NgClass, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  OnDestroy,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

import {
  CartItem,
  CartItemType,
  Consumable,
  CouponCartItem,
  GiftCartItem,
  GiftFormat,
  StandardCartItem
} from '../../../../../core/model/cart/cart-item.model';
import { AvailableSpaBooking } from '../../../../../core/model/spa-booking.model';
import { environment } from '../../../environments/environment';
import { emulateClick } from '../../core/a11y';
import { GiftFormComponent } from '../../gift-form/gift-form.component';
import { SpaBookingComponent } from '../../shared/components/spa-booking/spa-booking.component';
import { HoverGradientDirective } from '../../shared/directives/hover-gradient.directive';
import { Center } from '../../shared/model/center.model';
import { Product } from '../../shared/model/product.model';
import { AccountService } from '../../shared/services/account.service';
import { AuthService } from '../../shared/services/auth.service';
import { CartService } from '../../shared/services/cart.service';
import { ProductService } from '../../shared/services/product.service';
import { SiteService } from '../../shared/services/site.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-cart-item-detail',
  imports: [
    NgOptimizedImage,
    HoverGradientDirective,
    NgxTolgeeModule,
    NgClass,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    GiftFormComponent,
    DatePipe,
    CurrencyPipe,
    SpaBookingComponent
  ],
  templateUrl: './cart-item-detail.component.html'
})

export class CartItemDetailComponent implements OnDestroy {
  readonly emulateClick = emulateClick;
  platformId = inject(PLATFORM_ID);
  authService = inject(AuthService);
  productService = inject(ProductService);
  cartService = inject(CartService);
  siteService = inject(SiteService);
  accountService = inject(AccountService);
  showPlanning = environment.showPlanning;
  item = model.required<CartItem>();
  asGift = computed(() => {
    const item = this.item();
    return item instanceof GiftCartItem ? item as GiftCartItem : null
  })
  spaBookingData = signal<{ center: Center, item: StandardCartItem, spaBookings: AvailableSpaBooking[] } | null>(null)
  product = computed(() => this.productService.get(this.item().product) as Product)
  canDelete = input(true);
  images = computed(() => {
    const product = this.product();
    if (product.group) {
      return this.siteService.images(product.group);
    }
    return this.siteService.images(product.id);
  });
  asCoupon = computed(() => {
    const item = this.item();
    return item instanceof CouponCartItem ? item as CouponCartItem : null
  })
  planningDisplayed = signal(false);
  confirmAsGift = signal(false);
  nextYear = ((): Date => new Date(new Date().setFullYear(new Date().getFullYear() + 1)))();
  spaBookingProgress = signal(false);
  spaBookingTimer = signal('');
  editGift = signal(false);
  interval: ReturnType<typeof setTimeout> | null = null;
  checkout = input(false);
  protected readonly CartItemType = CartItemType;
  protected readonly GiftFormat = GiftFormat;

  constructor() {
    effect(async () => {
      const center = this.siteService.center();
      const item = this.item();
      const product = this.product();
      const cartSaving = this.cartService.saving();
      if (!center || !product || !item || cartSaving) {
        return;
      }
      if (!(item instanceof StandardCartItem) || !product.onAppointment) {
        return;
      }

      const standardItem = item as StandardCartItem;
      this.spaBookingProgress.set(true);
      const spaBookings = await this.accountService
        .availableSpaForProductGift(center.erpId, product.erpId);
      this.spaBookingData.set({ center: center, item: standardItem, spaBookings });
      this.spaBookingProgress.set(false);
    }, { allowSignalWrites: true });
    if (isPlatformBrowser(this.platformId)) {
      this.interval = setInterval(async () => await this.remainingTime(), 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Update gift information (ex. email, address, etc.)
   * @param cartItem The cart item to update
   */
  async updateGiftData(cartItem: CartItem): Promise<void> {
    const previous = this.item();
    const current = new GiftCartItem({ ...cartItem, product: previous.product });
    await this.cartService.update(previous, current);
  }

  /**
   * Remove an item from cart
   */
  async removeItemFromCart(): Promise<void> {
    if (this.cartService.saving()) {
      return;
    }
    await this.cartService.remove(this.item());
  }

  /**
   * Add an item to cart
   */
  async addItemToCart(): Promise<void> {
    if (this.cartService.saving()) {
      return;
    }
    const item = this.item();
    if (item instanceof StandardCartItem) {
      item.spaBooking = null;
    }
    await this.cartService.add([{ cartItem: item, quantity: 1 }], this.item());
  }

  /**
   * The user selects a valid reservation slot
   * @param slot New SPA booking slot
   * @param slot.date New SPA booking date
   * @param slot.time New SPA booking slot hour
   * @param slot.productId New SPA booking slot product
   * @param slot.created New SPA booking slot creation date
   */
  async updateSpaOption(slot: { date: string; time: string; productId: number, created: string }): Promise<void> {
    const item = this.item();
    if (item instanceof StandardCartItem) {
      item.spaBooking = slot;
    }
    this.planningDisplayed.set(false);
    await this.remainingTime();
    await this.cartService.save();
    await this.cartService.refreshCart()
  }

  /**
   * Calculate remaining time
   */
  async remainingTime(): Promise<void> {
    const product = this.spaBookingData();
    if (product?.item.spaBooking) {
      const date: string = product?.item.spaBooking.created;
      const startTime = new Date();
      const endTime = new Date(date);
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);
      const remainingTime = 20 - Math.abs(resultInMinutes);
      this.spaBookingTimer.set(String(remainingTime));
      if (remainingTime <= 0) {
        const item = this.item();
        if (item instanceof StandardCartItem) {
          item.spaBooking = null;
          await this.cartService.save();
        }
      }
    }
  }

  selectedType(consumable: Consumable): 'anonymous' | 'spa' | 'credit' | null {
    const selected = consumable.items.find(i => i.consumeOid === consumable.oIds[0]);
    if (!selected) {
      return null
    }
    if (selected.type === 'anonymous') {
      return 'anonymous'
    }
    if (selected.onAppointement) {
      return 'spa'
    }
    return 'credit'
  }

  toggleGift(): void {
    if (this.asGift() || !this.spaBookingData()?.item.spaBooking) {
      this.cartService.switchGift(this.item());
      return;
    }
    this.confirmAsGift.set(true);
  }

  confirmGift(): void {
    this.cartService.switchGift(this.item());
    this.confirmAsGift.set(false);
  }
}
